import React from 'react';
import { Layout } from 'antd';
import { ReactComponent as Copyright2023Logo } from 'assets/icons/copyright _2023.svg';
import { ReactComponent as EuropeanUnionLogo } from 'assets/icons/european_union.svg';
import { ReactComponent as EspaLogo } from 'assets/icons/espa.svg';
import { ReactComponent as GreeceGovernmentLogo } from 'assets/icons/government-greece-logo2.svg';
import { ReactComponent as GreenMinistryLogo } from 'assets/icons/green_ministry_gov.svg';
import TaipedLogo from 'assets/icons/taipedLogo.jpeg';

const { Content } = Layout;

const Footer = () => {
  return (
    <Content className="footer">
      <div className="footer-logo">
        <GreenMinistryLogo className="contents-icons" />
      </div>
      <div className="taiped-logo-wrapper">
        {/* Use img tag instead of the component */}
        <img 
          src={TaipedLogo} 
          alt="Taiped Logo"
          className="taiped-logo"
        />
      </div>
      <div className="footer-logo">
        <EuropeanUnionLogo className="contents-icons" />
      </div>
      <div className="footer-logo">
        <GreeceGovernmentLogo className="contents-icons" />
      </div>
    </Content>
  );
};

export default Footer;